import { initializeApp } from "firebase/app";
import { getAnalytics, setUserId , setUserProperties, logEvent } from "firebase/analytics";
import { getAuth,   signInWithRedirect, getRedirectResult, GoogleAuthProvider, onAuthStateChanged, signOut } from "firebase/auth";

// TODO : Change this when environment change is needed
// const env = "staging"
const env = "prod"

const stagingFirebaseConfig = {
  apiKey: "AIzaSyAiEFzXWTnTf3b0ESiVf7XNg7bNqBDDoy4",
  authDomain: "bethebestai-staging.firebaseapp.com",
  projectId: "bethebestai-staging",
  storageBucket: "bethebestai-staging.appspot.com",
  messagingSenderId: "444211237566",
  appId: "1:444211237566:web:e7ab66f07de1281713c4ef",
  measurementId: "G-MQJWMDDJG8"
};

// Working one
const oldStagingFirebaseConfig = {
  apiKey: "AIzaSyCF204R2IVYknO7RWfyIgVSsS9eMBSjcl8",
  authDomain: "platform10x.firebaseapp.com",
  projectId: "platform10x",
  storageBucket: "platform10x.appspot.com",
  messagingSenderId: "852305635064",
  appId: "1:852305635064:web:d965f93c542354a18c7d0d",
  measurementId: "G-XHR8MBNQGC"
};

const prodFirebaseConfig = {
    apiKey: "AIzaSyACZbTGXzRWTpxU0ps_60uCBza0eFmEMSY",
    authDomain: "bethebestai.firebaseapp.com",
    projectId: "bethebestai",
    storageBucket: "bethebestai.appspot.com",
    messagingSenderId: "31780408124",
    appId: "1:31780408124:web:166d9005f33928080c2c39",
    measurementId: "G-GGGFFD4K8B"
};

let firebaseConfig =  prodFirebaseConfig
if(env == "prod") {
    firebaseConfig = prodFirebaseConfig
} else if(env == "staging") {
    firebaseConfig = stagingFirebaseConfig
} else {
    firebaseConfig = oldStagingFirebaseConfig
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export let currentUser = null
export let currentUserId = ""
export let currentUserName = "";
export let currentUserDisplayName = "";
export let currentUserEmail = "";
export let currentUserPhotoUrl = "";
export let isAuthUserUpdated = false

onAuthStateChanged(auth, (user) => {
    isAuthUserUpdated = true;
    if (user) {
        // console.log(JSON.stringify(user));
        currentUserId = user.uid;
        currentUserEmail = user.email;
        currentUserName = user.email;
        currentUserDisplayName = user.displayName;
        currentUserPhotoUrl = user.photoURL;
        console.log("AuthStateChanged Logged In : " + currentUserId);
    } else {
        currentUserId = ""
        currentUserEmail = ""
        currentUserName = ""
        currentUserDisplayName = ""
        currentUserPhotoUrl = ""
        console.log("AuthStateChanged Logged Out" );
    }
});

export async function loginWithGoogle() {
    let provider = new GoogleAuthProvider()
    signInWithRedirect(auth, provider);
    const redirectResult = await getRedirectResult(auth);
}

export function logout() {
    isAuthUserUpdated = false;
    signOut(auth)
    .then(() => {
          console.log("Logout successful");
        }).catch((error) => {
          console.log("Logout Failed");
        }
    );
}

export function logAnalyticsEvent(eventName) {
    console.log("Pushing Event to Firebase");
    logEvent(analytics, eventName);
}


export function logAnalyticsEventWithData(eventName, params) {
    // logEvent(analytics, eventName, params);
}
